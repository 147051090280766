<template>
  <div>
      <div class="22" style="position:fixed;top:0;left:0;right: 0; right: 0px; z-index: 10;">
      <van-nav-bar left-arrow @click-left="$router.back(-1)" fixed="true" :right-text="righttext">
        <div slot="title">
         粉丝订单
        </div>
      </van-nav-bar>
    </div>
    <!-- 导航 -->
   <div class="123" style="margin:40px 0;">
   <van-tabs swipeable @change="onChange" :active="active">
  
   <van-tab title="全部">
      <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
           <van-cell>
               <van-col span="8">{{item.shopName}}</van-col>
               <van-col span="10" offset="6" class="word9">{{item.myShopStatus == 1? '待付款': item.myShopStatus == 2?'待发货': 
                    item.myShopStatus == 3? '退款/售后':  ''}}</van-col>
           </van-cell>
       </div>
    <!---商品信息--->
       <div>
        <van-card
         :num="1"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="20">
              <span v-html="item.description"></span>
             </van-col>
              <van-col span="4">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共1件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">40</van-col>
           </van-cell>
       </div>
       <!----->
              <div>
            <van-cell v-show="item.myShopStatus == 1?true:false">
               <van-col span="7" offset="10"> <van-button   plain style="border-radius: 20px; height:28px;width:94px">取消订单</van-button></van-col>
               <van-col span="7" > <van-button color="#FF3C00"  style="border-radius: 20px; height:28px;width:84px；">付款</van-button></van-col>
           </van-cell>
             <van-cell  v-show="item.myShopStatus == 2?true:false">
             <van-col span="7"  offset="17" > <van-button color="#FF3C00"  style="border-radius: 20px; height:28px;width:84px；">提醒发货</van-button></van-col>
           </van-cell>
             <van-cell v-show="item.myShopStatus == 3?true:false" >
             <van-col span="7"  offset="17"> <van-button color="#FF3C00" 
               @click="$router.push({name:'Commentshop',query: {'Id':item.id}})"
              style="border-radius: 20px; height:28px;width:84px">评价</van-button></van-col>
           </van-cell>

       </div>
       </div>
       </van-list>
   </van-tab>
   <van-tab title="待付款"> 
             <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
           <van-cell>
               <van-col span="8">{{item.shopName}}</van-col>
               <van-col span="8" offset="8" class="word9">待付款</van-col>
           </van-cell>
       </div>
    <!---商品信息--->
       <div>
        <van-card
         :num="1"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="20">
              <span v-html="item.description"></span>
             </van-col>
              <van-col span="4">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共1件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">40</van-col>
           </van-cell>
       </div>
       <!----->
              <div>
            <van-cell>
               <van-col span="7" offset="10"> <van-button   plain style="border-radius: 20px; height:28px;width:94px">取消订单</van-button></van-col>
               <van-col span="7" > <van-button color="#FF3C00"  style="border-radius: 20px; height:28px;width:84px；">付款</van-button></van-col>
           </van-cell>
       </div>
       </div>
       </van-list>
       </van-tab>


    <van-tab title="待发货"> 
       
         <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
           <van-cell>
               <van-col span="8">{{item.shopName}}</van-col>
               <van-col span="8" offset="8" class="word9">待发货</van-col>
           </van-cell>
       </div>
    <!---商品信息--->
       <div>
        <van-card
         :num="1"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="20">
              <span v-html="item.description"></span>
             </van-col>
              <van-col span="4">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共1件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">40</van-col>
           </van-cell>
       </div>
       <!----->
              <div>
            <van-cell>
               <van-col span="7" offset="17"> <van-button   plain style="border-radius: 20px; height:28px;width:94px">提醒发货</van-button></van-col>
           </van-cell>
       </div>
       </div>
       </van-list>
       </van-tab>
    <van-tab title="待收货"> 
       
         <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
           <van-cell>
               <van-col span="8">{{item.shopName}}</van-col>
               <van-col span="8" offset="8" class="word9">待收货</van-col>
           </van-cell>
       </div>
    <!---商品信息--->
       <div>
        <van-card
         :num="1"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="20">
              <span v-html="item.description"></span>
             </van-col>
              <van-col span="4">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共1件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">40</van-col>
           </van-cell>
       </div>
       <!----->
              <div>
            <van-cell>
               <van-col span="7" offset="17"> <van-button   plain style="border-radius: 20px; height:28px;width:94px">提醒发货</van-button></van-col>
           </van-cell>
       </div>
       </div>
       </van-list>
       </van-tab>

  <van-tab title="退款/售后"> 
         <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
           <van-cell>
               <van-col span="8">{{item.shopName}}</van-col>
               <van-col span="6" offset="10" class="word9">待评价</van-col>
           </van-cell>
       </div>
    <!---商品信息--->
       <div>
        <van-card
         :num="1"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="20">
              <span v-html="item.description"></span>
             </van-col>
              <van-col span="4">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共1件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">40</van-col>
           </van-cell>
       </div>
       <!----->
              <div>
            <van-cell>
               <van-col span="7" offset="17"> <van-button color="#FF3C00" 
                style="border-radius: 20px; height:28px;width:84px;float:right"
                @click="$router.push({name:'Commentshop',query: {'Id':item.id}})"
                >评价</van-button></van-col>
           </van-cell>
       </div>
       </div>
       </van-list>
       </van-tab>
   </van-tabs>
    </div>
      <!-- 订单信息 -->

  </div>
</template>
<script>
import { getfansOrderNew } from  '@/api/fansOrderNew/fansOrderNew'
export default {
  name: "fansOrderdatanew",
  
  data() {   
    return {
      page: 1,//请求第几页
      pageSize: 20,//每页请求的数量
      total: 0,//总共的数据条数
      getfansOrderlist:[],
      active:0,
      finished:'',
      loading:'',
      righttext:'',
      // fixed:''
    };
  },
  created() {
    if(this.$route.query.active) {
      this.active = +this.$route.query.active
    }
  },
  mounted() {
      if(this.$route.query.session!=null)
      {
        this.session=this.$route.query.session
        this.$store.commit('setSession', this.session)
      }
    this.getfansOrderNewtype(0)
  },
  methods:{
    
     getfansOrderNewtype(status){

      let params1 ={'current':this.page,'size':this.pageSize}
      let params = {}

      if(status>0)
      {
          params={myShopStatus:status}
      }
       getfansOrderNew(Object.assign(params,params1)).then(res =>{
           let rows = res.data.data.records; //请求返回当页的列表
           this.total=res.data.data.total
         // 将新数据与老数据进行合并
         if(res.data.code===0)
         {
            this.getfansOrderlist = this.getfansOrderlist.concat(rows)
         }
        //alert(this.fansData.length)
       //如果列表数据条数>=总条数，不再触发滚动加载
        this.loading = false;
        //alert(JSON.stringify(this.total))
        if (rows == null || rows.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        if (this.getfansOrderlist.length>=this.total) {
           this.finished = true;
        }
      })
    },
     //上滑加载
     onLoad() {
      this.page++;
      alert(this.page)
      this.getfansOrderNewtype(this.active);
    },
      onChange(event)
    {
      console.log(event);
      this.page=1;
      this.getfansOrderlist=[]
      this.getfansOrderNewtype(event)
      //alert(JSON.stringify(this.getfansOrderlist.length))
    },
  },
  components: {},
};
</script>
<style scoped>
.van-card {
    position: relative;
    box-sizing: border-box;
    padding: 8px 16px;
    color: #323233;
    font-size: 12px;
    background-color: hsl(0deg 0% 100% / 90%);
}
.info3-order {
  margin-right:2px;
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  align-self: center;
}
.word9 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 82, 0, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  line-height: 20px;
  text-align: right;
}
</style>
